import { Paragraph, SectionTitle, StyledLink } from 'src/components/Typography';
import { graphql, useStaticQuery } from 'gatsby';

import OnlineOnsiteBlock from './OnlineOnsiteBlock';
import React from 'react';
import { Section } from '../Section';
import { SectionHeaderBlock } from 'src/components/Section/elements';
import { TitleDescription } from '../Typography';
import colors from 'src/style/colors';
/* --- import Media Queries ----*/
import { mediaQueries } from 'src/style/breakpoints';

const OnlineOnsite = React.memo(({ theme }) => {
  const data = useStaticQuery(graphql`
    query {
      rncp5: file(relativePath: { eq: "2023_students4.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 70, width: 550)
        }
      }
      rncp6PartTime: file(relativePath: { eq: "2023_students5.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 70)
        }
      }
      rncp6FullTime: file(relativePath: { eq: "2023_students2.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 70)
        }
      }
      rncp6Alternance: file(relativePath: { eq: "2023_studentsAndTeacher4.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 70)
        }
      }
    }
  `);

  // MODIFIER CES FICHIERS EN MÊME TEMPS : gatsby-node.js et TopBarData.js

  const program = {
    'rncp5-e-learning': {
      title: 'E-learning + mentoring',
      duration: '3 à 6 mois',
      tags: ['Web', 'diplômant bac+2', 'e-learning'],
      image: data.rncp5.childImageSharp.gatsbyImageData,
      imageDescription: 'Le Reacteur : Formation Développeur Web',
      content: (
        <Paragraph>
          Vous voulez mettre un pied dans la programmation Web, en avançant à votre rythme ?
          Embarquez sur un parcours en e-learning en étant accompagné, chaque semaine, par un
          formateur (en visio), auquel vous pourrez poser toutes vos questions. Passez un Titre de{' '}
          <StyledLink to="/titre-rncp-developpeur-web">Développeur Web</StyledLink> (Bac+2), à la
          fin de votre formation. Un véritable tremplin vers notre{' '}
          <StyledLink to="/formation-alternance/concepteur-developpeur-applications/">
            formation en alternance
          </StyledLink>{' '}
          !
        </Paragraph>
      ),
      link: '/cours-informatique/formation-developpeur-web/',
      isActive: true,
    },
    'rncp6-fullTime': {
      title: 'Bootcamp Temps Plein',
      duration: '10 semaines',
      tags: ['Web+Mobile', 'diplômant bac+3/4', 'onSiteOrOnline'],
      image: data.rncp6FullTime.childImageSharp.gatsbyImageData,
      imageDescription: 'Le Reacteur Paris : Formation Développeur Web et Mobile',
      content: (
        <Paragraph>
          Suivez les cours à temps plein pendant 10 semaines intensives, pour devenir{' '}
          <StyledLink to="/titre-rncp-concepteur-developpeur-d-applications">
            Concepteur Développeur d'Applications
          </StyledLink>{' '}
          (Bac+3/4), auprès de formateurs expérimentés, à distance depuis chez vous, ou en
          présentiel, dans nos locaux à Paris. Partez en stage rémunéré (facultatif), pendant 6
          mois, après la formation pour compléter votre parcours.
        </Paragraph>
      ),
      link: '/bootcamp-javascript/formation-developpeur-web-mobile/',
      isActive: true,
    },
    'rncp6-partTime': {
      title: 'Bootcamp Temps Partiel',
      duration: '6 mois',
      tags: ['Web+Mobile', 'diplômant bac+3/4', 'online'],
      image: data.rncp6PartTime.childImageSharp.gatsbyImageData,
      imageDescription: 'Le Reacteur : Formation Développeur Web et Mobile à distance',
      content: (
        <Paragraph>
          Nous avons décliné notre bootcamp sur un format dédié aux personnes qui ne peuvent se
          libérer en semaine. Depuis chez vous, nos formateurs vous apprennent à coder en live (via
          Zoom), le mardi et jeudi soir ainsi que chaque samedi, toute la journée. Une salle de
          classe vivante où les élèves apprennent à devenir{' '}
          <StyledLink to="/titre-rncp-concepteur-developpeur-d-applications">
            Concepteur Développeur d'Applications
          </StyledLink>{' '}
          (Bac+3/4), pendant 6 mois. Un stage est possible en fin de parcours.
        </Paragraph>
      ),
      link: '/bootcamp-javascript/formation-developpeur-web-mobile-temps-partiel/',
      isActive: true,
    },
    'rncp6-alternance': {
      title: 'Formation en alternance',
      duration: '15 à 17 mois',
      tags: ['Web+Mobile', 'diplômant bac+3/4', 'onSiteAndOrOnline'],
      image: data.rncp6Alternance.childImageSharp.gatsbyImageData,
      imageDescription: 'Le Reacteur Paris : Formation Développeur Web et Mobile en alternance',
      content: (
        <Paragraph>
          Faites le choix d'alterner entre périodes de formation et périodes en entreprise en
          signant un CDI d'entrée de jeu ! Passez directement votre Titre RNCP de{' '}
          <StyledLink to="/titre-rncp-concepteur-developpeur-d-applications">
            Concepteur Développeur d'Applications
          </StyledLink>{' '}
          (Bac+3/4), en fin de cursus. Nous vous aiderons à trouver une entreprise d'accueil pour
          formaliser un contrat d'apprentissage ou de professionnalisation. Un titre RNCP5 est
          fortement recommandé avant d'entamer ce parcours (plus facile pour être recruté), mais pas
          obligatoire si vous savez déjà coder.
        </Paragraph>
      ),
      link: '/formation-alternance/concepteur-developpeur-applications/',
      isActive: true,
    },
    blockchain: {
      title: 'Bootcamp Blockchain',
      duration: '9 semaines',
      tags: ['Web3/Blockchain', 'certifiant', 'online'],
      image: data.rncp5.childImageSharp.fluid,
      imageDescription: 'Le Reacteur Paris : visio-conférence',
      content: (
        <Paragraph>
          Vous êtes déjà développeur et souhaitez rentrer dans le monde du Web3 ? Entamez une
          formation en cours du soir, du lundi au jeudi, 12 heures par semaine (3 heures chaque
          soir). Vous apprendrez à développer des applications basées sur la Blockchain Ethereum
          grâce à Solidity.
        </Paragraph>
      ),
      link: '/bootcamp-blockchain/formation-developpeur-web3-blockchain-solidity',
      isActive: false,
    },
  };

  return (
    <div>
      <Section
        theme={theme ? theme : 'light'}
        css={{
          [mediaQueries.phoneOnly]: {
            paddingBottom: 56,
          },
        }}
      >
        <SectionHeaderBlock css={{ margin: 'auto', marginBottom: 72, paddingBottom: 0 }}>
          <SectionTitle isMobileCenter isCenter>
            Devenez développeur
            <br />
            <span css={{ color: colors.purple }}>Web et Mobile</span>
          </SectionTitle>
          <TitleDescription css={{ margin: '15px 0 20px', fontWeight: 'lighter' }}>
            Plusieurs formats adaptés{' '}
            <br
              css={{
                [mediaQueries.desktopUp]: {
                  display: 'none',
                },
              }}
            />
            à vos attentes et disponibilités
          </TitleDescription>
        </SectionHeaderBlock>

        {Object.keys(program)
          .filter((el) => program[el].isActive)
          .map((elem, index) => {
            return program[elem].isActive ? (
              <OnlineOnsiteBlock
                key={program[elem].title}
                {...program[elem]}
                even={index % 2 === 0}
                isLast={Object.keys(program).length === index + 1}
              />
            ) : null;
          })}
      </Section>
    </div>
  );
});
export default OnlineOnsite;
